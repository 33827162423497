import { useEffect, useState } from 'react';

const useImagePreloader = (imageUrls: string[]): boolean => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const preloadImage = (src: string): Promise<void> => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve();
                img.onerror = (err) => reject(err);
            });
        };

        const preloadAllImages = async () => {
            try {
                await Promise.all(imageUrls.map(preloadImage));
                setIsLoaded(true);
            } catch (error) {
                console.error('Error preloading images:', error);
            }
        };

        if (imageUrls && imageUrls.length > 0) {
            preloadAllImages();
        }
    }, [imageUrls]);

    return isLoaded;
};

export default useImagePreloader;
