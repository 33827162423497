import React from 'react';

const LoginArrow = () => {
    return (
        <svg width="34" height="23" viewBox="0 0 34 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_di_442_236)">
                <path
                    d="M33.1088 10.1273C32.8902 9.90912 32.8902 9.69094 32.6717 9.47276L23.9298 0.745488C23.0557 -0.127239 21.7444 -0.127239 20.8702 0.745488C19.996 1.61822 19.996 2.92731 20.8702 3.80003L25.8968 8.81821H2.73087C1.41959 8.81821 0.54541 9.69094 0.54541 11C0.54541 12.3091 1.41959 13.1819 2.73087 13.1819H25.8968L20.8702 18.2C19.996 19.0728 19.996 20.3819 20.8702 21.2546C21.3073 21.6909 21.9629 21.9091 22.4 21.9091C22.8371 21.9091 23.4927 21.6909 23.9298 21.2546L32.6717 12.5273C32.8902 12.3091 33.1088 12.0909 33.1088 11.8728C33.3273 11.2182 33.3273 10.7819 33.1088 10.1273Z"
                    fill="#101212"
                    fillOpacity="0.5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_di_442_236"
                    x="0.327228"
                    y="0.0909424"
                    width="33.1637"
                    height="22.9091"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.272727" />
                    <feGaussianBlur stdDeviation="0.109091" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_442_236" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_442_236" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.09091" />
                    <feGaussianBlur stdDeviation="0.545455" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_442_236" />
                </filter>
            </defs>
        </svg>
    );
};

export default LoginArrow;
