import React, { ReactNode } from 'react';
import { clsx } from 'clsx';

type ButtonProps = {
    children?: ReactNode;
    icon?: ReactNode;
    iconPosition?: 'leading' | 'trailing';
    variant?: 'primary' | 'secondary' | 'tertiary' | 'theGathering';
    size?: 'shorty' | 'long' | 'small' | 'login' | 'xsmall' | 'large';
    onClick?: () => void;
    className?: string;
};

const Button: React.FC<ButtonProps> = ({
    children,
    icon,
    iconPosition = 'leading',
    variant = 'primary',
    size = 'shorty',
    onClick,
    className,
    ...props
}) => {
    const baseStyles = 'flex items-center justify-center font-medium rounded-full text-base';

    const sizeStyles = {
        small: 'text-xs px-4 py-2',
        large: 'px-6 py-4',
        shorty: 'px-4 py-2',
        long: 'px-4 py-2 w-80',
        login: 'py-10 px-16 text-5xl h-[175px]',
        xsmall: 'px-2 !text-[8px]',
    };

    const variantStyles = {
        primary: 'bg-vitlGreen-gradient text-vitlBlack',
        secondary: 'bg-vitlBlack-gradient text-white',
        tertiary: 'bg-transparent border border-vitlGreen',
        theGathering: 'bg-[linear-gradient(180deg,rgba(255,255,255,0.28)_0%,rgba(119,119,119,0.28)_100%)] hover:opacity-80 border-[1px] border-[linear-gradient(180deg,rgba(255,255,255,0.28)_0%,rgba(119,119,119,0.28)_100%)]'
    };

    const isIconOnly = !children && icon;
    const iconOnlyStyles = isIconOnly && 'w-12 h-12';

    const iconClass = clsx({
        'mr-2': icon && children && iconPosition === 'leading',
        'ml-2': icon && children && iconPosition === 'trailing',
    });

    return (
        <div
            className={clsx(
                'flex items-center justify-center rounded-full',
                size === 'login' ? 'p-[2px]' : 'p-[0.5px]',
                className,
                ['primary', 'secondary'].includes(variant) && 'bg-gradient-to-b from-vitlWhite to-[#777777]',
            )}
        >
            <button
                className={clsx(baseStyles, sizeStyles[size], variantStyles[variant], iconOnlyStyles, className)}
                onClick={onClick}
                {...props}
            >
                {icon && iconPosition === 'leading' && <span className={iconClass}>{icon}</span>}
                {children && <span>{children}</span>}
                {icon && iconPosition === 'trailing' && <span className={iconClass}>{icon}</span>}
            </button>
        </div>
    );
};

export default Button;
